body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bill table {
  border-collapse: collapse;
}

.bill  td, .bill th {
border: 1px solid #999;
padding: 0.5rem;
text-align: left;
}

@media print {
  table {
      border: solid #000 !important;
      border-width: 1px 0 0 1px !important;
  }
  th, td {
      border: solid #000 !important;
      border-width: 0 1px 1px 0 !important;
  }
}